import React from 'react';


export class Header extends React.Component {
    public render() {

        return (
            <div className="App-header">
                <div className="Header-Main">
                    <div className="App-title"> Camren Daly</div>
                    <div className="App-subtitle"> A study in doing various things</div>
                </div>
            </div>
        );
    }
}